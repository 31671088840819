import React from 'react'
import '../Design/About.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons'
function About() {
  return (
    <div>
      <section class="portfolio-block photography">
    <div class="container">
        <div class="row g-0">
            <div class="col-md-6 col-lg-4 item zoom-on-hover"><img class="img-fluid image" src="https://cdnuploads.aa.com.tr/uploads/Contents/2020/05/20/thumbs_b_c_2d5ba06a294d9d2ef4c264db01b3e594.jpg" /></div>
            <div class="col-md-6 col-lg-4 item zoom-on-hover"><img class="img-fluid image" src="https://listelist.com/wp-content/uploads/2020/06/collage2-750x375.jpg" /></div>
            <div class="col-md-6 col-lg-4 item zoom-on-hover"><img class="img-fluid image" src="https://images.pexels.com/photos/6316065/pexels-photo-6316065.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" /></div>
        </div>
    </div>
</section>
      <div class="container py-5">
    <div class="mx-auto div-cart">
        <div class="row row-cols-1 row-cols-md-2 d-flex justify-content-center">
            <div class="col mb-4">
                <div class="card">
                    <div class="card-body text-center px-4 py-5 px-md-5">
                        <p class="fw-bold text-primary card-text mb-2">Eğem İklimlendirme: İdeal İklimlendirme Çözümleri</p>
                        <h5 class="fw-bold card-title mb-3 text">Eğem İklimlendirme, sıcak yaz günlerinde serinliği evinize getirmenin ve soğuk kış aylarında sizi sıcak tutmanın en iyi yoludur. Biz, yılların deneyimi ve uzman ekibimizle, iklimlendirme ihtiyaçlarınıza özel çözümler sunuyoruz.

Klima bakımı, cihazınızın performansını artırmak ve uzun ömürlü olmasını sağlamak için önemlidir. Eğem İklimlendirme olarak, klima bakımı hizmetlerimizle cihazınızın sorunsuz çalışmasını garanti ediyoruz.

Eğem İklimlendirme ile yaşam alanlarınızı daha konforlu ve verimli hale getirin. İdeal iklimlendirme çözümleri için bize bugün ulaşın.</h5>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card">
                    <div class="card-body text-center px-4 py-5 px-md-5">
                        <p class="fw-bold text-two card-text mb-2">Klima Bakımı: Eğem İklimlendirme'nin Uzmanlığı</p>
                        <h5 class="fw-bold card-title mb-3">Eğem İklimlendirme, klima bakımı konusundaki uzmanlığı ile öne çıkıyor. Klima bakımı, cihazınızın performansını artırmanın ve enerji tasarrufu sağlamanın anahtarıdır. Eğem İklimlendirme olarak, klimanızın her yıl düzenli bakımını yaparak size konforlu bir yaşam alanı sunmaya yardımcı oluyoruz.

Klima bakımı sürecimiz, filtre temizliği, gaz kontrolü, fan temizliği ve daha fazlasını içerir. Cihazınızın sorunsuz çalışmasını sağlamak için gereken her şeyi yapıyoruz.

Eğem İklimlendirme ile klimanızı en iyi şekilde koruyun ve performansını artırın. Size özel bakım paketlerimizi incelemek için bizimle iletişime geçin.</h5>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card">
                    <div class="card-body text-center px-4 py-5 px-md-5">
                        <p class="fw-bold text-info card-text mb-2">Eğem İklimlendirme ile İdeal İklimlendirme Deneyimi</p>
                        <h5 class="fw-bold card-title mb-3">Eğem İklimlendirme, iç mekan iklimlendirme ihtiyaçlarınıza çözüm sunmak için burada. Sıcak yaz günlerinde serinlemek ve soğuk kış aylarında sıcak tutmak için klima sistemlerimize güvenebilirsiniz.

Ama iş bitmiyor! Klima bakımı da önemli. Eğem İklimlendirme'nin uzman teknisyenleri, klimanızı düzenli olarak bakımını yaparak en yüksek verimlilikte çalışmasını sağlar. Bu da enerji tasarrufu ve uzun ömür demektir.

Eğem İklimlendirme ile yaşam alanlarınızı daha konforlu hale getirin ve klima bakımı ile cihazınızın ömrünü uzatın. Bizimle iletişime geçerek daha fazla bilgi alabilirsiniz.</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row gy-2 row-cols-1 row-cols-md-4 stats-row col-alt">
    <div class="col">
        <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
            <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><svg class="bi bi-bell" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"></path>
                </svg></div>
            <div class="px-3">
                <h2 class="fw-bold mb-0">200+</h2>
                <p class="mb-0">Büyük Proje Hizmeti</p>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
            <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><svg class="bi bi-bezier" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M0 10.5A1.5 1.5 0 0 1 1.5 9h1A1.5 1.5 0 0 1 4 10.5v1A1.5 1.5 0 0 1 2.5 13h-1A1.5 1.5 0 0 1 0 11.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10.5.5A1.5 1.5 0 0 1 13.5 9h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM6 4.5A1.5 1.5 0 0 1 7.5 3h1A1.5 1.5 0 0 1 10 4.5v1A1.5 1.5 0 0 1 8.5 7h-1A1.5 1.5 0 0 1 6 5.5v-1zM7.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"></path>
                    <path d="M6 4.5H1.866a1 1 0 1 0 0 1h2.668A6.517 6.517 0 0 0 1.814 9H2.5c.123 0 .244.015.358.043a5.517 5.517 0 0 1 3.185-3.185A1.503 1.503 0 0 1 6 5.5v-1zm3.957 1.358A1.5 1.5 0 0 0 10 5.5v-1h4.134a1 1 0 1 1 0 1h-2.668a6.517 6.517 0 0 1 2.72 3.5H13.5c-.123 0-.243.015-.358.043a5.517 5.517 0 0 0-3.185-3.185z"></path>
                </svg></div>
            <div class="px-3">
                <h2 class="fw-bold mb-0">30+</h2>
                <p class="mb-0">Referans</p>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
            <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><svg class="bi bi-cup" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M.11 3.187A.5.5 0 0 1 .5 3h13a.5.5 0 0 1 .488.608l-.22.991a3.001 3.001 0 0 1-1.3 5.854l-.132.59A2.5 2.5 0 0 1 9.896 13H4.104a2.5 2.5 0 0 1-2.44-1.958L.012 3.608a.5.5 0 0 1 .098-.42Zm12.574 6.288a2 2 0 0 0 .866-3.899l-.866 3.9ZM1.124 4l1.516 6.825A1.5 1.5 0 0 0 4.104 12h5.792a1.5 1.5 0 0 0 1.464-1.175L12.877 4H1.123Z"></path>
                </svg></div>
            <div class="px-3">
                <h2 class="fw-bold mb-0">1000+</h2>
                <p class="mb-0">Müşteri Memnuniyeti</p>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
            <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><svg class="bi bi-cart2" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
                </svg></div>
            <div class="px-3">
                <h2 class="fw-bold mb-0">15+</h2>
                <p class="mb-0">Marka Yelpazesi</p>
            </div>
        </div>
    </div>
</div>
</div>
  )
}

export default About  