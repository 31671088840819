import React from 'react'
import '../Design/Services.css'
function Services() {
  return (
    <div className="all-services">
      <div class="container py-4 py-xl-5">
    <div class="row mb-5">
        <div class="col-md-8 col-xl-6 text-center mx-auto">
            <h2>Egem Klima ve İklimlendirme: İç Mekan Konforunuzu Mükemmelleştirin</h2>
            <p class="w-lg-50">Egem Klima ve İklimlendirme, iç mekan konforunuza özen gösteriyor. Klima bakımı, tamiri, montajı ve daha fazlası için profesyonel hizmetler sunuyoruz. İç mekan hava kalitesini artırmak ve enerji tasarrufu sağlamak için bize güvenin</p>
        </div>
    </div>
    <div class="row gy-4 row-cols-1 row-cols-md-2">
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block fit-cover img-services" src="https://blog.ofix.com/wp-content/uploads/2018/05/klima_bakimi_ofix_blog.jpg" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Klima Bakımı</h4>
                    <p>Klima bakımı, iç mekan hava kalitesini artırmak ve enerji tasarrufu sağlamak için önemli bir adımdır. Uzman teknisyenlerimiz, filtre temizliği, gaz kontrolü ve fan bakımı gibi işlemleri titizlikle gerçekleştirir. Klimanızın uzun ömürlü olmasını ve en iyi şekilde çalışmasını sağlamak için bizimle iletişime geçin.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block fit-cover img-services" src="https://toshiba-aircon.com.au/wp/wp-content/uploads/2021/06/air-conditioner-installer.jpg" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Klima Tamiri</h4>
                    <p>Klima arızaları sıcak yaz günlerinde rahatsızlık yaratabilir. Ekibimiz, klimanızın hızlı bir şekilde onarılmasını sağlar. Soğutmama, su sızıntıları veya gürültülü çalışma gibi sorunlarla başa çıkmak için buradayız. İklimlendirme konforunu geri kazanmak için bizimle iletişime geçin.</p>
                </div>
            </div>
        </div>
        <div class="col py-5">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block fit-cover img-services" src="https://4.imimg.com/data4/YB/FO/MY-19238423/split-ac-installation-500x500.jpg" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Klima Montajı</h4>
                    <p>Yeni bir klima sistemi kurmak mükemmel iç mekan konforu sağlayabilir. Uzman montaj ekibimiz, klimanızı güvenle kurar ve sistemini optimize eder. Doğru montaj, cihazınızın en iyi şekilde çalışmasını ve enerji tasarrufu yapmasını sağlar. Konforlu bir yaşam alanı için bize başvurun.</p>
                </div>
            </div>
        </div>
        <div class="col py-5">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block fit-cover img-services" src="https://media.angi.com/s3fs-public/ac-technician.jpeg?impolicy=leadImage" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Klima Servisi</h4>
                    <p>Klimanızın düzenli servisi, beklenmedik arızaları önlemenin anahtarıdır. Servis planlarımız, cihazınızın performansını optimize etmek ve verimliliğini artırmak için özel olarak tasarlanmıştır. İç mekan konforunu korumak için uzmanlarımızla çalışın.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container py-4 py-xl-5 services-2">
    <div class="row gy-4 row-cols-1 row-cols-md-2">
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block fit-cover img-services" src="https://www.kalemuhendislik.com.tr/img/monoblok-isi-pompasi-1920w.jpg" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Isı Pompası Hizmetleri</h4>
                    <p>Isı pompaları, evinizi hem ısıtmak hem de soğutmak için etkili bir seçenektir. Uzmanlarımız, ısı pompası kurulumu, bakımı ve tamiri konusunda uzmandır. Ev konforunu optimize etmek için enerji verimliliğini artırın.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block fit-cover img-services" src="https://beeimg.com/images/b51174118811.png" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Klima Enerji Verimliliği Danışmanlığı</h4>
                    <p>Enerji verimliliği, hem çevreye hem de cüzdanınıza fayda sağlar. Klima enerji verimliliği danışmanlarımız, cihazınızın enerji tüketimini optimize etmek için size rehberlik eder. İklimlendirme maliyetlerinizi azaltmak için bize danışın.</p>
                </div>
            </div>
        </div>
        <div class="col py-5">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block fit-cover img-services" src="https://www.colesrefrigeration.com.au/wp-content/uploads/2017/10/vrv-and-vrf-systems-banner-1024x682.jpg" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Havalandırma Sistemleri</h4>
                    <p>Havalandırma Sistemleri: Temiz Hava Kalitesini Garanti Edin"
Açıklama: İyi bir havalandırma sistemi, iç mekan hava kalitesini iyileştirmenin önemli bir yoludur. Havalandırma sistemi kurulumu ve bakımında deneyime sahibiz. Temiz hava solumak için bize güvenin.</p>
                </div>
            </div>
        </div>
        <div class="col py-5">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block fit-cover img-services" src="https://m.media-amazon.com/images/I/71F-Asb29yL.jpg" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Klima Yedek Parça Temini</h4>
                    <p>Klima Yedek Parça Temini: Sorunlara Hızlı Çözüm"
Açıklama: Klima yedek parçaları, cihazınızın sorunsuz çalışmasını sürdürmenize yardımcı olur. Geniş yedek parça envanterimizle, ihtiyacınız olan parçaları hızlıca temin edebiliriz. Klima sorunlarına anında çözüm sunmak için bize ulaşın.</p>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default Services