import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faGlobe, faCircleNodes, faPhone } from '@fortawesome/free-solid-svg-icons';
import Loading from './Loading';
// import './bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';

import '../Design/Home.css';
import '../Design/Gallery.css';

function Home() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="home bg-white">
            {isLoading ? (
                <Loading /> 
            ) : (
                <div>
                    <div className="header-container img-col">
                        <div className="container">
                            <div className="col-md-9 col-xl-7 text-center mx-auto header-first">
                                <h1 className="text-center text-dark fw-bold">Egem Klima ve İklimlendirme</h1>
                                <p className="fw-bold text-center">Egem İklimlendirme ve Klima Bakım olarak, yıllardır iklimlendirme ve soğutma çözümleri sunuyoruz. Müşterilerimize konforlu bir yaşam sunmak için uzman ekibimizle birlikte çalışıyoruz. İhtiyaçlarınıza özel çözümler sunarak, sıcaklığı kontrol altında tutuyoruz.</p>
                                <p className="fw-bold text-center">İklimlendirme sistemlerinin kurulumundan bakımına kadar, Egem İklimlendirme olarak kaliteli hizmetimizle güvende olacaksınız. Müşteri memnuniyeti ve enerji verimliliği, önceliklerimiz arasında yer almaktadır.</p>
                                <p className="fw-bold text-center">Size en iyi iklimlendirme çözümlerini sunmak için buradayız. Sıcaklığı bizimle kontrol edin ve rahatlığın tadını çıkarın. Egem İklimlendirme ve Klima Bakım, konforunuz için burada!</p>
                            </div>
                        </div>
                    </div>

                    <div className="container bg-white py-5 ">
                        <div className="row ">
                            <div className="col-md-8 col-xl-6 text-center mx-auto text-col">
                                <p className="fw-bold text-primary mb-2">Egem Klima olarak, rahatlığınız ve konforunuz için buradayız!</p>
                                <h5 className="fw-bold">Siz sadece dinlenin, sıcaklığı bize bırakın. İklimlendirme ve soğutma konularında uzman ekibimizle, daha serin, daha rahat ve daha iyi bir yaşam için yanınızdayız. Sizin için neler yapabiliriz?</h5>
                            </div>
                        </div>

                        <div class="py-5 p-lg-5">
                            <div class="row row-cols-1 row-cols-md-2 mx-auto text-col">
                                <div class="col mb-5">
                                    <div class="card shadow-sm">
                                        <div class="card-body px-4 py-5 px-md-5">
                                            <div class="bs-icon-lg d-flex justify-content-center align-items-center mb-3 bs-icon" style={{ top: '1rem', right: '1rem', position: 'absolute' }}>
                                                <FontAwesomeIcon icon={faBuilding} size="2xl" />
                                            </div>
                                            <h5 class="fw-bold card-title text-primary">Hakkımızda</h5>
                                            <p class="text-muted card-text mb-4">Uzun yılların verdiği deneyimle, Egem Klima olarak iklimlendirme ve soğutma alanında güvenilir bir çözüm sağlayıcısıyız. Müşterilerimize en iyi hizmeti sunarak konforlarını artırmak için çalışıyoruz. Kalite, uzmanlık ve müşteri memnuniyeti, işimizin temelidir. Sizleri daha yakından tanımak ve ihtiyaçlarınıza en uygun çözümü sunmak için buradayız. Egem Klima, konforunuzu önemsiyor.</p>
                                            <button class="btn btn-dark shadow" type="button"><Link to='/hakkimizda'>Daha Fazlasını Oku</Link></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-5">
                                    <div class="card shadow-sm">
                                        <div class="card-body px-4 py-5 px-md-5">
                                            <div class="bs-icon-lg d-flex justify-content-center align-items-center mb-3 bs-icon" style={{ top: '1rem', right: '1rem', position: 'absolute' }}>
                                                <FontAwesomeIcon icon={faCircleNodes} size="2xl" />
                                            </div>
                                            <h5 class="fw-bold card-title text-primary">Hizmetlerimiz</h5>
                                            <p class="text-muted card-text mb-4">Konforunuzu sağlamak için yıllardır süren deneyim ve uzmanlıkla iklimlendirme çözümleri sunuyoruz. Klima kurulumundan bakımına, enerji verimliliği danışmanlığından hava temizleme sistemlerine kadar ihtiyacınız olan her şeyi sunuyoruz. Kalite, güvenilirlik ve müşteri memnuniyeti önceliğimizdir. Size en iyi hizmeti sunmak için buradayız. İletişime geçin, konforunuzu bizimle deneyimleyin.</p>
                                            <button class="btn btn-dark shadow" type="button"><Link to='./Hizmetlerimiz'>Hizmetlerimizi Gör</Link></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card shadow-sm">
                                        <div class="card-body px-4 py-5 px-md-5">
                                            <div class="bs-icon-lg d-flex justify-content-center align-items-center mb-3 bs-icon" style={{ top: '1rem', right: '1rem', position: 'absolute' }}>
                                                <FontAwesomeIcon icon={faGlobe} size="2xl" />
                                            </div>
                                            <h5 class="fw-bold card-title text-primary">Referanslarımız</h5>
                                            <p class="text-muted card-text mb-4">Referanslarımız, yılların verdiği güvenle iklimlendirme ve soğutma alanında sunduğumuz üstün hizmeti yansıtıyor. Müşterilerimizin memnuniyeti, en büyük başarımızdır. Onların deneyimleri bizim için ilham kaynağıdır. Size de referanslarımızın arasına katılmayı ve konforun tadını çıkarmayı öneriyoruz. Egem Klima, güvendiğiniz bir çözüm ortağıdır.</p>
                                            <button class="btn btn-dark shadow" type="button"><Link to='./References'>Referanslarımızı Tanı</Link></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card shadow-sm">
                                        <div class="card-body px-4 py-5 px-md-5">
                                            <div class="bs-icon-lg d-flex justify-content-center align-items-center mb-3 bs-icon" style={{ top: '1rem', right: '1rem', position: 'absolute' }}>
                                                <FontAwesomeIcon icon={faPhone} size="2xl" />
                                            </div>
                                            <h5 class="fw-bold card-title text-primary">İletişim</h5>
                                            <p class="text-muted card-text mb-4">Bize Ulaşın! İhtiyaçlarınızı dinlemek ve size en iyi iklimlendirme çözümlerini sunmak için buradayız. Sorularınızı yanıtlamak ve size yardımcı olmak için hazırız. İletişime geçin ve konforunuzu bizimle paylaşın. Egem Klima, sizinle iletişimde olmaktan memnuniyet duyar. Size en iyi hizmeti sunmak ve ihtiyaçlarınıza özel çözümler sunmak için iletişimde olmaktan mutluluk duyuyoruz.</p>
                                            <button class="btn btn-dark shadow" type="button"><Link to='./iletisim'>Bizimle İletişime Geç</Link></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Home;
