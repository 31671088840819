import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, NavLink } from 'react-router-dom';
import '../Design/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <div className={`App ${isOpen ? 'navbar--opened' : ''}`}>
      <nav className="nav navbar navbar-expand-md fixed-top navbar-dark position-fixed">
        <div className="container">
          <button className="navbar-toggler" onClick={toggleNavbar}>
            <span className="visually-hidden">Toggle navigation</span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link to="/" className="navbar-brand">
            <img src='https://beeimg.com/images/a86600666133.jpg' className="icon-img" alt="Logo" />
          </Link>
          <div>
            <a href="whatsapp://send?phone=+5325540085" className="btn btn-success"><i className="fab fa-whatsapp"></i>WhatsApp</a>
          </div>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
            <ul className="navbar-nav ">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" activeClassName="active" onClick={closeNavbar}>
                  Anasayfa
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/hakkimizda" className="nav-link" activeClassName="active" onClick={closeNavbar}>
                  Hakkımızda
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/hizmetlerimiz" className="nav-link" activeClassName="active" onClick={closeNavbar}>
                  Hizmetlerimiz
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/references" className="nav-link" activeClassName="active" onClick={closeNavbar}>
                  Referanslarımız
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/iletisim" className="nav-link" activeClassName="active" onClick={closeNavbar}>
                  İletişim
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
