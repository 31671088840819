import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from './Home/Navbar';
import './Design/index.css';

import Home from './Home/Home';
import About from './Home/About';
import Services from './Home/Services';
import Contact from './Home/Contact';
import References from './Home/References';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hakkimizda" element={<About />} />
        <Route path="/hizmetlerimiz" element={<Services />} />
        <Route path="/references" element={<References />} />
        <Route path="/iletisim" element={<Contact />} />
      </Routes>
    </Router>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
