import React from 'react'
import '../Design/References.css'

function References() {
  return (
    <div className="references">
      <div class="container py-4 py-xl-5">
    <div class="row mb-5">
        <div class="col-md-8 col-xl-6 text-center mx-auto">
            <h2>Referanslarımız</h2>
            <p class="w-lg-50">Biz, klima ve iklimlendirme alanında uzun yıllardır hizmet veren bir ekip olarak, sektörde güvenilir ve kaliteli markalarla işbirliği yapmaktan gurur duyuyoruz. Müşterilerimize en iyi ürünleri sunmak için her zaman en iyi markaları tercih ediyor ve bu yazıda size bu markalar hakkında daha fazla bilgi vermek istiyoruz..</p>
        </div>
    </div>
    <div class="row gy-4 row-cols-1 row-cols-md-2">
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block fit-cover col-ref" src="https://folkart.com.tr/assets/img/folkart_logo_black.svg" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Folkart Towers</h4>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block w-100 fit-cover col-ref2" src="https://haberdairesi.com/d/news/87058.jpg" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>İzmir Büyükşehir Belediyesi</h4>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block w-100 fit-cover col-ref" src="https://www.cityconstructioninsaat.com/wp-content/uploads/2022/07/cityconstruction.png" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>City Construction İnşaat</h4>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block w-100 fit-cover col-ref" src="https://files.sikayetvar.com/lg/cmp/15/153596.png?1648837240" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Işıklı İnşaat</h4>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block  fit-cover col-ref3" src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Logo_Ege_Uni.png" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>Ege Üniversitesi</h4>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column flex-lg-row">
                <div class="w-100"><img class="rounded img-fluid d-block w-100 fit-cover col-ref" src="https://www.cms.com.tr/assets/frontend/img/logo-footer.svg" /></div>
                <div class="py-4 py-lg-0 px-lg-4">
                    <h4>CMS</h4>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default References