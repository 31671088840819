import React from 'react';
import '../Design/Loading.css'

const Loading = () => {
  return (
    <div className="loading">
      <img src="https://beeimg.com/images/b51174118811.png" alt="Restaurant Logo" className='restaurant-logo' />
      <span class="loader"></span>
    </div>
  );
};

export default Loading;
